import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/backbone-models",
  "date": "2014-12-03",
  "title": "BACKBONE – MODELS",
  "author": "admin",
  "tags": ["development", "javascript", "backbone.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Models are the heart of any JavaScript MVC application, containing the interactive data as well as a large part of the logic surrounding it: conversions, validations, computed properties, and access control."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Generating a model class:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var TodoItem = Backbone.Model.extend({});
`}</code></pre>
    <p>{`Generating a model instance:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var todoItem = new TodoItem({ 
    description: 'Pick up milk', 
    status: 'incomplete' 
});
`}</code></pre>
    <p>{`To get an attribute:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`todoItem.get('description');
`}</code></pre>
    <p>{`To set an attribute:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`todoItem.set({status: 'complete'});
`}</code></pre>
    <h2>{`Fetching Data from the Server`}</h2>
    <p>{`Let's create a Backbone model to fetch data from the server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var todoItem = new TodoItem();
`}</code></pre>
    <p>{`URL to get JSON data for model:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`todoItem.url = '/todo';
`}</code></pre>
    <p>{`To populate model from server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`todoItem.fetch();
`}</code></pre>
    <p>{`Result:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{ id: 1, description: 'Pick up milk', status: 'incomplete' }
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`todoItem.get('description');
`}</code></pre>
    <p>{`Result:`}</p>
    <pre><code parentName="pre" {...{}}>{`'Pick up milk'
`}</code></pre>
    <p>{`Now let's call the RESTful web service:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var TodoItem = Backbone.Model.extend({urlRoot: '/todos'});
`}</code></pre>
    <p>{`Fetch todo with id = 1`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var todoItem = new TodoItem({id: 1});
todoItem.fetch();
`}</code></pre>
    <p><strong parentName="p">{`fetch`}</strong>{` will call `}<inlineCode parentName="p">{`GET /todos/1`}</inlineCode></p>
    <p>{`Result:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{ id: 1, description: 'Pick up milk', status: 'incomplete' }
`}</code></pre>
    <p>{`To update the todo:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`todoItem.set({description: 'Pick up cookies.'});
todoItem.save();
`}</code></pre>
    <p>{`This is calling PUT /todos/1 with JSON params.`}</p>
    <h2>{`Creating & Destroying a New Todo`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var todoItem = new TodoItem();
todoItem.set({description: 'Fill prescription.'});

todoItem.save(); // POST /todos with JSON params

todoItem.get('id');  // 2

todoItem.destroy();
`}</code></pre>
    <h2>{`Get JSON from model`}</h2>
    <p>{`To get JSOn from the model:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`todoItem.toJSON();
`}</code></pre>
    <p>{`Result:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{ id: 2, description: 'Fill prescription', status: 'incomplete' }
`}</code></pre>
    <h2>{`Default Values`}</h2>
    <p>{`Let's see how we can set default values in Backbone.`}</p>
    <pre><code parentName="pre" {...{}}>{`js
    var TodoItem = Backbone.Model.extend({
        defaults: {
            description: 'Empty todo...',
            status: 'incomplete'
        }
    });

    var todoItem = new TodoItem();
    todoItem.get('description'); // 'Empty todo...'
    todoItem.get('status'); //'incomplete'
`}</code></pre>
    <h2>{`Models Can Have Events`}</h2>
    <p>{`To listen for an event on a model:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`todoItem.on('event-name', function(){
    alert('event-name happened!');
});
`}</code></pre>
    <p>{`Run the event:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`todoItem.trigger('event-name');
`}</code></pre>
    <h2>{`Special Events`}</h2>
    <p>{`Here are some special events of Backbone:`}</p>
    <p>{`To listen for changes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`todoItem.on('change', doThing);

Event triggered on change:
\`\`\`js
var doThing = function() {
    ...
}
`}</code></pre>
    <p>{`Set without triggering event:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`todoItem.set({description: 'Fill prescription.'},
    {silent: true});
`}</code></pre>
    <p>{`Remove event listener`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`todoItem.off('change', doThing);
todoItem.on(<event>, <method>);
`}</code></pre>
    <p>{`Built-in events:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`change`}</strong>{` When an attribute is modified `}</li>
      <li parentName="ul"><strong parentName="li">{`change:`}</strong><inlineCode parentName="li">{`<attr>`}</inlineCode>{` When `}<inlineCode parentName="li">{`<attr>`}</inlineCode>{` is modified `}</li>
      <li parentName="ul"><strong parentName="li">{`destroy`}</strong>{` When an attribute is modified `}</li>
      <li parentName="ul"><strong parentName="li">{`sync`}</strong>{` When a model is destroyed `}</li>
      <li parentName="ul"><strong parentName="li">{`error`}</strong>{` When model save or validation fails `}</li>
      <li parentName="ul"><strong parentName="li">{`all`}</strong>{` Any triggered event`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      